import Base from '@/libs/core/Base'

export const fields = {
  name: '',
  category_uid: '',
}

export default class Resource extends Base {
  constructor () {
    super(fields)
  }
  
  store () {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.form.submit('post', 'silo/resources', this.getFields())
        this.setFields(fields)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }
}