<template>
  <base-dialog-form
    :dialog="dialog"
    :loading="loading"
    :title="title"
    actionText="Save resource details"
    @close="$emit('close')"
    @submit="submit()"
  >
    <template #content>
      <v-text-field
        dense
        outlined
        persistent-hint
        label="Name *"
        class="rounded-lg"
        v-model="resourceObj.name"
        :hint="errors.get('name')"
        :error="errors.has('name')"
        @input="errors.clear('name')"
      ></v-text-field>

      <v-select
        dense
        outlined
        persistent-hint
        label="Category *"
        class="rounded-lg"
        item-text="name"
        item-value="category_uid"
        :items="categories.data"
        v-model="resourceObj.category_uid"
        :hint="errors.get('category_uid')"
        :error="errors.has('category_uid')"
        @input="errors.clear('category_uid')"
      ></v-select>
    </template>
  </base-dialog-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Resource from '@/libs/silo/Resource'

export default {
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },

    resource: {
      type: Object
    }
  },

  data () {
    return {
      loading: false,
      resourceObj: new Resource(),
    }
  },

  computed: {
    ...mapGetters({
      categories: 'getFarmResourceCategories',
    }),

    title () {
      const action = this.resource ? 'Edit' : 'Create'
      return `${action} Resource`
    },

    errors () {
      return this.resourceObj.form.errors
    },
  },

  methods: {
    ...mapActions([
      'setFarmResourceCategories',
      'setFarmResources',
    ]),

    mapResource (resource) {
      if (resource) {
        Object.keys(resource).forEach(key => {
          if (this.resourceObj.hasOwnProperty(key)) {
            this.resourceObj[key] = resource[key]
          }
        })
      }
    },

    submit () {
      if (!this.loading) {
        this.loading = true
        !this.resource ? this.store() : this.update()
      }
    },

    store () {
      this.resourceObj.store()
        .then(() => {
          this.setFarmResources()
            .then(() => {
              this.$emit('close')
            })
        }).finally(() => {
          this.loading = false
        })
    },

    // update () {
    //   this.boundaryObj.update(this.boundary.boundary_uid)
    //     .then(() => {
    //       this.setBoundaries()
    //         .then(() => {
    //           this.$emit('close')
    //         })
    //     }).finally(() => {
    //       this.loading = false
    //     })
    // },
  },

  mounted () {
    this.mapResource()
    this.setFarmResourceCategories()
  }
}
</script>